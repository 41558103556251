<template>
  <div>
    <loader v-show="isLoading"></loader>
    <div class="layoutBorder" v-if="!isLoading && responseError.length > 0">
      <div class="layoutHeader">
        <h4>Ошибка</h4>
      </div>
      <div class="layoutBody">
        <div>{{responseError}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "pdlEnter",
    data() {
      return {
        isLoading: true,
        responseError: '',
      }
    },
    components: {
      loader: () => import('@/components/other/loader'),
    },
    methods: {
      signIn(loanId) {
        this.$palantirLnd.trackWebAction(95, loanId, localStorage.getItem('sessionLnd'));

        this.$external.universalRequest({
          externalType: 'zita',
          action: 'signinFromLink',
          loanId: loanId,
        })
        .then((res) => {
          this.responseError = '';
          this.$store.commit('setUserID', res.UserID);
          this.$store.commit('setSessionKey', res.SessionKey);
          localStorage.setItem('defPass',true);

          this.$palantirLnd.trackWebAction(95, loanId, localStorage.getItem('sessionLnd'));

          if (localStorage.getItem('PDL')  == 'true') {
            this.$router.push('/pdl/loanEnding');
          } else {
            this.$router.push('/pdl/loanBaseAwait');
          }
        })
        .catch((res) => {
          let trackId = 94;
          try {

            if (res.errorMsg == 301) {
              localStorage.removeItem('SessionKey');
              localStorage.removeItem('UserID');
              this.$store.commit('resetState');
              this.$store.commit('setSessionKey', '');
              this.$store.commit('setUserID', '');
              trackId = 93;
              if (localStorage.getItem('PDL') == 'true') {
                localStorage.setItem('redirect','/pdl/loanEnding');
              } else {
                localStorage.setItem('redirect','/pdl/loanBaseAwait');
              }
              this.$router.push('/login');
            }
            this.responseError = res.errorMsg;
          } catch (e) {
            this.responseError = JSON.stringify(res);
          }
          this.$palantirLnd.trackWebAction(trackId, this.responseError, localStorage.getItem('sessionLnd'));
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
    mounted() {
      this.isLoading = true;
      let params = this.$route.query;
      this.$palantirLnd.trackWebAction(91, document.location.search, params['session']);

      if (params['demand'] != undefined
          && parseInt(params['demand'], 10) > 0
          && params['isPDL'] != undefined) {
        localStorage.setItem('demand',params['demand']);
        localStorage.setItem('PDL',params['isPDL']);
        localStorage.setItem('loanId',params['loanId']);
        localStorage.setItem('sessionLnd',params['session']);
        this.signIn(params['loanId']);
      } else {
        if (params['loanId'] != undefined && parseInt(params['loanId'], 10) > 0) {
          localStorage.setItem('loanId', params['loanId']);
          localStorage.setItem('sessionLnd',params['session']);
          this.signIn(params['loanId']);
        } else {
          this.isLoading = false;
          this.responseError = 'Не корректная ссылка';
          this.$palantirLnd.trackWebAction(97, '', localStorage.getItem('sessionLnd'));
        }
      }
    }
  }
</script>

<style scoped>

</style>
